<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row class="justify-content-end">
            <b-col cols="6">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  :placeholder="$t('Common.Search')"
                  v-model="filters.search"
                  @input="onSearch"
                />
              </b-input-group>
            </b-col>
          </b-row>

          <div class="box mt-2">
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              primary-key="id"
              empty-text="No matching records found"
              show-empty
              :per-page="pagination.perPage"
              :current-page="pagination.currentPage"
              :items="WebsiteUsersLists"
              :fields="fields"
            >
              <template #head()="data">{{ $t(data.label) }}</template>
              <template #cell(user_name)="data">
                {{ data.item.user_first_name + " " + data.item.user_last_name }}
              </template>
              <template #cell(view)="data">
                <feather-icon icon="EyeIcon" @click="$router.push(`/website-users-details/${data.item.user_id}`)" />
              </template>
            </b-table>
          </div>

          <b-card class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="WebsiteUsersLists.length"
                :per-page="pagination.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BInputGroupPrepend,
  BCard,
  BTable,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BDropdown,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BPagination,
  BInputGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import WebsiteUsersServices from "@/apiServices/WebsiteUsersServices";

export default {
  directives: {
    Ripple,
  },
  components: {
    FeatherIcon,
    BInputGroupPrepend,
    BCard,
    BTable,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BDropdown,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
  },
  data() {
    return {
      pagination: {
        currentPage: 1,
        perPage: 50,
      },
      WebsiteUsersLists: [],
      filters: {
        search: "",
      },
      searchTimeout: null,
      fields: [
        {
          key: "user_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "user_email",
          label: "Email",
          sortable: true,
        },
        {
          key: "user_mobile",
          label: "Mobile",
          sortable: true,
        },
        {
          key: "view",
          label: "View",
        },
      ],
    };
  },
  methods: {
    onSearch(text) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getAllUsersList();
      }, 500);
    },
    async getAllUsersList() {
      try {
        const response = await WebsiteUsersServices.getAllWebsiteUsers(
          this.filters
        );
        if (response.data.status) {
          this.WebsiteUsersLists = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
    // async showUserDetails(data) {
    //   try {
        
    //   } catch (err) {
    //     console.log("Error in getting  ", err);
    //   }
    // },
  },
  beforeMount() {
    this.getAllUsersList();
  },
};
</script>
